"use client";
import React, { FC } from "react";

interface BurgerMenuProps {
	isOpen: boolean;
	setOpen: (isOpen: boolean) => void;
}

const BurgerMenu: FC<BurgerMenuProps> = ({ isOpen, setOpen }) => {
	if (!isOpen)
		return (
			<div
				className={" h-6 w-8 flex flex-col justify-between transition-all duration-500"}
				onClick={() => setOpen(true)}
			>
				<div className={"bg-white rounded-full w-8 h-1 transition-all duration-200"}></div>
				<div className={"bg-white rounded-full w-8 h-1 transition-all duration-500"}></div>
				<div className={"bg-white rounded-full w-8 h-1 transition-all"}></div>
			</div>
		);

	return (
		<div
			className={
				" h-6 w-8 flex flex-col justify-between transition-all rotate-90 duration-500"
			}
			onClick={() => setOpen(false)}
		>
			<div
				className={
					"bg-white rounded-full w-8 h-1 transition-all rotate-45 translate-y-2.5 duration-200"
				}
			></div>
			<div
				className={"bg-white rounded-full w-8 h-1 transition-all rotate-135 duration-500"}
			></div>
			<div
				className={
					"bg-white rounded-full w-8 h-1 transition-all -rotate-45 -translate-y-2.5"
				}
			></div>
		</div>
	);
};

export default BurgerMenu;