"use client";
import React from "react";
import { useCookies } from "react-cookie";
import { useRouter } from "next/navigation";
import Link from "next/link";

const Cookies = () => {
	const router = useRouter();
	const [cookies, setCookie] = useCookies(["accepted", "rejected"]);

	if (cookies.accepted || cookies.rejected) return null;

	return (
		<div
			className={
				"fixed bottom-0 left-0 w-screen h-72 lg:h-24 bg-gradient-to-r from-gray-950 to-gray-700 z-50 flex justify-center items-center shadow-md"
			}
		>
			<div
				className={
					"max-w-7xl w-full m-auto flex-col lg:flex-row flex justify-center lg:justify-between items-baseline lg:items-center px-8 lg:px-4 h-full gap-16 text-gray-200 font-light "
				}
			>
				<div className={""}>
					<span>
						Cybrix uses cookies to provide necessary website functionality, improve your
						experience and analyze our traffic. By using our website, you agree to our
						legal policies:{" "}
					</span>
					<Link className={"inline underline "} href={"/privacy_policy"}>
						Privacy Policy
					</Link>
					,{" "}
					<Link className={"inline underline "} href={"/cookies_policy"}>
						Cookies Policy
					</Link>
				</div>
				<div className={"flex gap-4"}>
					<button
						className={
							"h-4 lg:h-6 border-sky-700 bg-gray-200 text-gray-950 border rounded-3xl p-4 lg:p-5 px-4 lg:px-6 flex text-sm lg:text-base items-center justify-center hover:bg-sky-700 hover:text-gray-950 active:bg-sky-800 active:text-gray-950"
						}
						onClick={() => {
							router.push("/");
							setCookie("accepted", true);
						}}
					>
						Accept
					</button>
					<button
						className={
							"h-4 lg:h-6 border-sky-700 bg-gray-400 text-gray-950 border rounded-3xl p-4 lg:p-5 px-4 lg:px-6 flex text-sm lg:text-base items-center justify-center hover:bg-sky-700 hover:text-gray-950 active:bg-sky-800 active:text-gray-950"
						}
						onClick={() => {
							router.push("/");
							setCookie("rejected", true);
						}}
					>
						Reject
					</button>
				</div>
			</div>
		</div>
	);
};

export default Cookies;
