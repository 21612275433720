"use client";
import React from "react";
import Link from "next/link";
import Logo from "@cybrix/app/logo.svg";
import { links } from "@cybrix/app/Navbar";
import AICPA from "@cybrix/app/aicpa.webp";
import Image from "next/image";
// import Clutch from "./clutch.svg";
// import Cb from "./cb.svg";
// import LinkedIn from "./LinkedIn.svg";
import { useForm } from "react-hook-form";
import { ContactForm, contactHandle } from "@cybrix/app/util";
import { useWindowSize } from "usehooks-ts";

// const icons = [Clutch, Cb, LinkedIn];

const Footer = () => {
	const { width } = useWindowSize();
	const isMobile = width < 768;
	const form = useForm<ContactForm>();
	if (isMobile)
		return (
			<div
				className={
					"pb-20 bg-gradient-to-r from-gray-950 to-gray-700 relative z-50 flex justify-center items-center flex-col gap-8 "
				}
			>
				<div className={"h-20 w-full flex px-8 justify-start items-center"}>
					<Link href={"/"}>
						<Logo className={"h-6 hover:brightness-75 active:brightness-50"} />
					</Link>
				</div>
				<div className={"fixed z-50 w-full h-0.5 bg-white"} />
				<div className={"flex justify-between items-baseline w-full px-8"}>
					<div className={"flex flex-col gap-4 w-1/2"}>
						{links.map(({ href, label }) => (
							<Link
								href={href}
								key={href}
								className={"text-gray-200 font-medium text-sm"}
							>
								{label}
							</Link>
						))}
					</div>
					<div
						className={
							"flex flex-col content-between justify-between place-content-between align-center h-full w-52"
						}
					>
						<div className={"flex-col flex gap-4"}>
							<div className={"text-sm text-gray-200 font-medium"}>
								SOC 2 Type II compliance
							</div>
							<div className={"relative w-20 aspect-square"}>
								<Image src={AICPA} alt={"AICPA"} fill={true} placeholder={"blur"} />
							</div>
						</div>
					</div>
				</div>
				<form
					className={"flex flex-col gap-8 items-baseline  w-full px-8	"}
					onSubmit={(e) => {
						form.handleSubmit(contactHandle)(e).then(() => {
							form.reset();
						});
					}}
				>
					<div className={"text-gray-200"}>
						{/* eslint-disable-next-line react/no-unescaped-entities */}
						LET'S PARTNER UP!
					</div>
					<input
						{...form.register("name", { required: true })}
						className={
							"text-sm lg:text-base bg-transparent border-b-2 h-8 text-gray-200 outline-0 w-full"
						}
						type="text"
						placeholder={"Name"}
						name="name"
						id="name"
					/>
					<input
						{...form.register("email", { required: true })}
						className={
							"text-sm lg:text-base bg-transparent border-b-2 h-8 text-gray-200 outline-0 w-full"
						}
						type="email"
						placeholder={"Email"}
						name="email"
						id="email"
					/>
					<input
						{...form.register("subject", { required: true })}
						className={
							"text-sm lg:text-base bg-transparent border-b-2 h-8 text-gray-200 outline-0 w-full"
						}
						type="text"
						placeholder={"Subject"}
						name="subject"
						id="subject"
					/>
					<input
						type={"submit"}
						className={
							"h-8 lg:h-10 w-auto border-sky-700 bg-gray-950 text-gray-200 border rounded-3xl px-6 flex items-center justify-center text-sm lg:text-base hover:bg-sky-700 hover:text-gray-950 active:bg-sky-800 active:text-gray-950"
						}
						value={"Send"}
					/>
				</form>
				<div className={"flex justify-between flex-col w-full px-8 gap-8"}>
					<div className={"flex flex-col gap-8 w-full"}>
						<div className={"font-light text-xs text-gray-200"}>
							Kaupmehe tn 7-120, Tallinn, Estonia
						</div>
						<div className={"font-light text-xs text-gray-200"}>
							Bogdan Khmelnitsky 21, Kyiv, Ukraine
						</div>
						<div className={"font-light text-xs text-gray-200"}>
							Nowy Świat 41A, Warszawa, Poland
						</div>
					</div>
					<div className={"text-gray-200 font-light text-sm lg:text-xs"}>
						Copyright © 2023 Cybrix. All rights reserved
					</div>
				</div>
			</div>
		);
	return (
		<div
			className={
				"h-96 bg-gradient-to-r from-gray-950 to-gray-700 relative z-50 flex justify-center items-center "
			}
		>
			<div
				className={
					"max-w-7xl w-full m-auto flex justify-between align-baseline px-4 h-72 gap-16"
				}
			>
				<div className={"flex justify-between flex-col"}>
					<div className={"flex flex-col gap-8 w-72"}>
						<Link href={"/"}>
							<Logo
								className={"h-4 lg:h-6 hover:brightness-75 active:brightness-50"}
							/>
						</Link>

						<div className={"font-light text-2xs lg:text-xs text-gray-200"}>
							Kaupmehe tn 7-120, Tallinn, Estonia
						</div>
						<div className={"font-light text-2xs lg:text-xs text-gray-200"}>
							Bogdan Khmelnitsky 21, Kyiv, Ukraine
						</div>
						<div className={"font-light text-2xs lg:text-xs text-gray-200"}>
							Nowy Świat 41A, Warszawa, Poland
						</div>
						<div className={"font-light text-xs text-gray-200"}></div>
					</div>
					<div className={"text-gray-200 font-light text-2xs lg:text-xs"}>
						Copyright © 2023 Cybrix. All rights reserved
					</div>
				</div>

				<div className={"flex flex-col gap-4"}>
					{links.map(({ href, label }) => (
						<Link
							href={href}
							key={href}
							className={"text-gray-200 font-medium text-xs lg:text-sm"}
						>
							{label}
						</Link>
					))}
				</div>

				<form
					className={"flex flex-col gap-8 align-baseline  w-72"}
					onSubmit={(e) => {
						form.handleSubmit(contactHandle)(e).then(() => {
							form.reset();
						});
					}}
				>
					<div className={"text-gray-200"}>
						{/* eslint-disable-next-line react/no-unescaped-entities */}
						LET'S PARTNER UP!
					</div>
					<input
						{...form.register("name", { required: true })}
						className={
							"text-sm lg:text-base bg-transparent border-b-2 h-8 text-gray-200 outline-0"
						}
						type="text"
						placeholder={"Name"}
						name="name"
						id="name"
					/>
					<input
						{...form.register("email", { required: true })}
						className={
							"text-sm lg:text-base bg-transparent border-b-2 h-8 text-gray-200 outline-0"
						}
						type="email"
						placeholder={"Email"}
						name="email"
						id="email"
					/>
					<input
						{...form.register("subject", { required: true })}
						className={
							"text-sm lg:text-base bg-transparent border-b-2 h-8 text-gray-200 outline-0"
						}
						type="text"
						placeholder={"Subject"}
						name="subject"
						id="subject"
					/>
					<input
						type={"submit"}
						className={
							"h-8 lg:h-10 w-auto border-sky-700 bg-gray-950 text-gray-200 border rounded-3xl px-6 flex items-center justify-center text-sm lg:text-base hover:bg-sky-700 hover:text-gray-950 active:bg-sky-800 active:text-gray-950"
						}
						value={"Send"}
					/>
				</form>

				<div
					className={
						"flex flex-col content-between justify-between place-content-between align-center h-full w-52"
					}
				>
					<div className={"flex-col flex gap-4"}>
						<div className={"font-light text-sm text-gray-200 font-medium"}>
							SOC 2 Type II compliance
						</div>
						<div className={"relative w-48 aspect-square"}>
							<Image src={AICPA} alt={"AICPA"} fill={true} placeholder={"blur"} />
						</div>
					</div>
					{/*<div className={"flex flex-col items-end gap-4"}>*/}
					{/*	<div className={"flex flex-row gap-4 "}>*/}
					{/*		{icons.map((Icon, index) => (*/}
					{/*			<Icon key={index} className={"h-6 rounded-sm"} />*/}
					{/*		))}*/}
					{/*	</div>*/}
					{/*</div>*/}
				</div>
			</div>
		</div>
	);
};

export default Footer;
