"use client";
import React, { useEffect } from "react";
import Logo from "./logo.svg";
import Link from "next/link";
import { usePathname } from "next/navigation";
import dynamic from "next/dynamic";
import { useWindowSize } from "usehooks-ts";
import BurgerMenu from "@cybrix/app/BurgerMenu";

const ContactModal = dynamic(() => import("@cybrix/app/ContactModal"), {
	loading: () => null,
});

export const links = [
	{ href: "/about_us", label: "About Us" },
	{ href: "/services", label: "Services" },
	{ href: "/expertise", label: "Expertise" },
	{ href: "/industries", label: "Industries" },
	// { href: "/case_studies", label: "Case Studies" },
	// { href: "/blog", label: "Blog" },
	// { href: "/careers", label: "Careers" },
];

const Navbar = () => {
	const { width } = useWindowSize();
	const isMobile = width && width < 768;
	const pathname = usePathname();
	const [modalOpen, setModalOpen] = React.useState(false);
	const [open, setOpen] = React.useState(false);

	useEffect(() => {
		if (open && width >= 768) setOpen(false);
	}, [width]);

	return (
		<nav className={"w-full h-20 lg:h-16 sticky z-50 top-0 bg-gray-950"}>
			{!isMobile ? null : (
				<div
					className={`h-full w-full fixed z-40 bg-gray-950 transition-all duration-500 ${
						open ? "translate-y-0" : "-translate-y-full"
					}`}
				>
					<div
						className={"pt-32 px-8 flex gap-6 flex-col items-baseline justify-between"}
					>
						{links.map(({ href, label }) => (
							<Link
								key={href}
								href={href}
								onClick={() => setOpen(false)}
								className={`block ${
									pathname.toLowerCase().includes(href.toLowerCase())
										? "text-sky-400"
										: ""
								} text-gray-200 text-lg transition-all duration-900 ${
									open ? "translate-y-0" : "-translate-y-[1000px]"
								}`}
							>
								{label}
							</Link>
						))}

						<button
							className={`transition-all duration-600 ${
								open ? "translate-y-0" : "-translate-y-[1000px]"
							} h-4 lg:h-6 border-sky-700 bg-gray-950 text-gray-200 border rounded-3xl p-4 lg:p-5 px-4 lg:px-6 flex text-sm lg:text-base items-center justify-center hover:bg-sky-700 hover:text-gray-950 active:bg-sky-800 active:text-gray-950`}
							onClick={() => setModalOpen(true)}
						>
							Contact Us
						</button>
					</div>
				</div>
			)}
			<div
				className={
					"max-w-7xl relative bg-gray-950  z-50 m-auto flex justify-between items-center px-8 md:px-4 h-full"
				}
			>
				<Link href={"/"} onClick={() => setOpen(false)}>
					<Logo
						className={"h-6 md:h-4 lg:h-6 hover:brightness-75 active:brightness-50"}
					/>
				</Link>
				{isMobile ? null : (
					<>
						<div className={"flex gap-16 justify-between items-center"}>
							{links.map(({ href, label }) => (
								<Link
									key={href}
									href={href}
									className={`block border-b-2 ${
										pathname.toLowerCase().includes(href.toLowerCase())
											? "border-sky-400"
											: "border-transparent"
									} hover:border-sky-700 text-gray-200 text-xs lg:text-sm`}
								>
									{label}
								</Link>
							))}
						</div>

						<button
							className={
								"h-4 lg:h-6 border-sky-700 bg-gray-950 text-gray-200 border rounded-3xl p-4 lg:p-5 px-4 lg:px-6 flex text-sm lg:text-base items-center justify-center hover:bg-sky-700 hover:text-gray-950 active:bg-sky-800 active:text-gray-950"
							}
							onClick={() => setModalOpen(true)}
						>
							Contact Us
						</button>
					</>
				)}
				{isMobile ? <BurgerMenu setOpen={setOpen} isOpen={open} /> : null}
				<ContactModal setOpen={setModalOpen} isOpen={modalOpen} />
			</div>
			{open ? (
				<div
					className={
						"fixed z-50 w-full h-0.5 bg-white transition-all translate-x-0 duration-500"
					}
				/>
			) : (
				<div
					className={
						"fixed z-50 w-full h-0.5 bg-white transition-all -translate-x-full duration-500"
					}
				/>
			)}
		</nav>
	);
};

export default Navbar;
