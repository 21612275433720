import axios from "axios";

export const webhookUrl =
	"https://hooks.slack.com/services/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";
export const contactHandle = async (contactForm: ContactForm) => {
	console.log(contactForm);

	const data = {
		text: `Name: ${contactForm.name} \n${contactForm.email} \n${contactForm.subject}`,
	};
	await axios.post(webhookUrl, JSON.stringify(data), {
		// withCredentials: false,
		// transformRequest: [
		// 	(data, headers) => {
		// 		delete headers.post["Content-Type"];
		// 		return data;
		// 	},
		// ],
	});
};

export interface ContactForm {
	name: string;
	email: string;
	subject: string;
}
